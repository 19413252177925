
const readingData = []

const titles = ['Population of Canada', 'Our beautiful Bangladesh', 'We are believing the situation', 'Large changes of environment', 'New world going to dead', 'Technology is power', 'English is our second language']
const status = ['All','active', 'pending', 'cancelled', 'running'];


for (let i = 0; i < 100; i++) {
  readingData.push({
    id: i,
    qId: 'Q10'+i,
    title: titles[Math.floor(Math.random() * 6)],
    item: 'MCQSA',
    bulk: [1,5,8,9,10,16].includes(i),
    usedFor: 'PTE2 | Practice 4 | Mock 2',
    highFrequency: true,
    createdAt: '25 May, 2020, 9:15am',
    createdBy: 'Kazi Shahin',
    status: status[Math.floor(Math.random() * 5)]
  });
}


const questionTypes = [
  {id: 1,  group: 'listening', name: 'Write From Dictation', component: 'WriteFromDictation', slug: 'write-from-dictation'},
  {id: 2,  group: 'writing', name: 'Summarization', component: 'Summarization', slug: 'summarization'},
  {id: 3,  group: 'listening', name: 'Summarize Spoken Test', component: 'SummarizeSpokenTest', slug: 'summarize-spoken-test'},
  {id: 4,  group: 'listening', name: 'Select Missing Word', component: 'SelectMissingWord', slug: 'select-missing-word'},
  {id: 5,  group: 'speaking', name: 'Retell Lecture', component: 'RetellLecture', slug: 'retell-lecture'},
  {id: 6,  group: 'speaking', name: 'Repeat Sentence', component: 'RepeatSentence', slug: 'repeat-sentence'},
  {id: 7,  group: 'reading', name: 'Re-Order Paragraph', component: 'ReOrderParagraph', slug: 're-order-paragraph'},
  {id: 8,  group: 'speaking', name: 'Read Aloud', component: 'ReadAloud', slug: 'read-aloud'},
  {id: 9,  group: 'listening', name: 'MCQ - Single Answers', component: 'MCQSingleAnswers', slug: 'mcq-single-answers'},
  {id: 10, group: 'listening',  name: 'MCQ - Multiple Answers', component: 'MCQMultipleAnswers', slug: 'mcq-multiple-answer'},
  {id: 11, group: 'reading',  name: 'MCQ - Choose Single Answer', component: 'MCQChooseSingleAnswer', slug: 'mcq-choose-single-answer'},
  {id: 12, group: 'reading',  name: 'MCQ - Choose Multiple Answer', component: 'MCQChooseMultipleAnswer', slug: 'mcq-choose-multiple-answer'},
  {id: 13, group: 'listening',  name: 'Fill in the Gap', component: 'FillInTheGap', slug: 'fill-in-the-gap'},
  {id: 14, group: 'listening',  name: 'Highlight Incorrect Word', component: 'HighlightIncorrectWord', slug: 'highlight-incorrect-word'},
  {id: 15, group: 'listening',  name: 'Highlight Correct Summery', component: 'HighlightCorrectSummery', slug: 'highlight-correct-summery'},
  {id: 16, group: 'reading',  name: 'Fill in the Gap - Drag & Drop', component: 'FillInTheGapDragAndDrop', slug: 'fill-in-the-gap-drag-and-drop'},
  {id: 17, group: 'reading',  name: 'Fill in the Gap - Drop Down', component: 'FillInTheGapDropDown', slug: 'fill-in-the-gap-drop-down'},
  {id: 18, group: 'writing',  name: 'Writing Essay', component: 'WritingEssay', slug: 'writing-essay'},
  {id: 19, group: 'speaking',  name: 'Describe Image', component: 'DescribeImage', slug: 'describe-image'},
  {id: 20, group: 'speaking',  name: 'Answer Short Question', component: 'AnswerShortQuestion', slug: 'answer-short-question'},
];


const mainNavigation = [
  { id: 1, icon: 'extension', name: 'PTE Question', path: 'teacher.pteQuestion' },
  { id: 2, icon: 'swap_vertical_circle', name: 'PTE Quiz', path: 'teacher.pteQuiz' },
]

const topNavigation = [
  { 
    id: 1, 
    icon: `
        <svg version="1.1" style="width: 50px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 234.287 234.288" style="enable-background:new 0 0 234.287 234.288;"
    xml:space="preserve">
      <g><g id="_x31_42_9_"><g><rect x="103.174" y="139.593" width="24.826" height="23.628"/><path d="M142.198,84.29c-2.463-2.43-5.688-4.421-9.658-5.959C128.549,76.769,123.671,76,117.865,76
                      c-5.898,0-10.885,0.894-14.982,2.694c-4.096,1.785-7.459,4.113-10.078,6.967c-2.62,2.871-4.575,6.116-5.845,9.729
                      c-1.277,3.625-2.031,7.302-2.272,11.039h24.946c-0.246-2.625,0.234-4.971,1.424-7.041c1.191-2.064,3.22-3.097,6.089-3.097
                      c4.613,0,6.916,2.026,6.916,6.083c0,1.356-0.391,2.506-1.191,3.458c-0.79,0.96-1.772,1.777-2.927,2.448
                      c-1.15,0.675-2.366,1.29-3.641,1.846c-1.272,0.561-2.381,1.076-3.344,1.556c-2.308,1.272-4.044,2.869-5.246,4.773
                      c-1.191,1.907-2.034,3.856-2.501,5.843c-0.485,2-0.724,3.94-0.724,5.84c0,1.838,0.048,3.351,0.125,4.539h22.066
                      c0-1.346,0.112-2.539,0.305-3.574c0.193-1.031,0.549-1.961,1.016-2.737c0.478-0.798,1.153-1.554,2.031-2.271
                      c0.869-0.73,1.986-1.437,3.331-2.152c1.991-1.031,3.951-2.052,5.86-3.045c1.909-0.992,3.631-2.249,5.189-3.763
                      c1.555-1.508,2.804-3.397,3.758-5.674c0.96-2.267,1.432-5.22,1.432-8.881c-0.162-2.636-0.822-5.388-1.975-8.305
                      C146.484,89.375,144.666,86.711,142.198,84.29z"/><path d="M173.834,0H26.928v234.288h180.431V32.504L173.834,0z M191.76,218.683H42.528V15.6h123.268v24.75h25.958v178.333H191.76z"/></g></g></g></svg>`,
    label: 'PTE Question', 
    path: 'pte-question.reading' 
  },
  { 
    id: 2, 
    icon: `
        <svg version="1.1" style="width: 50px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 234.287 234.288" style="enable-background:new 0 0 234.287 234.288;"
    xml:space="preserve">
      <g><g id="_x31_42_9_"><g><rect x="103.174" y="139.593" width="24.826" height="23.628"/><path d="M142.198,84.29c-2.463-2.43-5.688-4.421-9.658-5.959C128.549,76.769,123.671,76,117.865,76
                      c-5.898,0-10.885,0.894-14.982,2.694c-4.096,1.785-7.459,4.113-10.078,6.967c-2.62,2.871-4.575,6.116-5.845,9.729
                      c-1.277,3.625-2.031,7.302-2.272,11.039h24.946c-0.246-2.625,0.234-4.971,1.424-7.041c1.191-2.064,3.22-3.097,6.089-3.097
                      c4.613,0,6.916,2.026,6.916,6.083c0,1.356-0.391,2.506-1.191,3.458c-0.79,0.96-1.772,1.777-2.927,2.448
                      c-1.15,0.675-2.366,1.29-3.641,1.846c-1.272,0.561-2.381,1.076-3.344,1.556c-2.308,1.272-4.044,2.869-5.246,4.773
                      c-1.191,1.907-2.034,3.856-2.501,5.843c-0.485,2-0.724,3.94-0.724,5.84c0,1.838,0.048,3.351,0.125,4.539h22.066
                      c0-1.346,0.112-2.539,0.305-3.574c0.193-1.031,0.549-1.961,1.016-2.737c0.478-0.798,1.153-1.554,2.031-2.271
                      c0.869-0.73,1.986-1.437,3.331-2.152c1.991-1.031,3.951-2.052,5.86-3.045c1.909-0.992,3.631-2.249,5.189-3.763
                      c1.555-1.508,2.804-3.397,3.758-5.674c0.96-2.267,1.432-5.22,1.432-8.881c-0.162-2.636-0.822-5.388-1.975-8.305
                      C146.484,89.375,144.666,86.711,142.198,84.29z"/><path d="M173.834,0H26.928v234.288h180.431V32.504L173.834,0z M191.76,218.683H42.528V15.6h123.268v24.75h25.958v178.333H191.76z"/></g></g></g></svg>`, 
    label: 'PTE Quiz', 
    path: 'teacher.pteQuiz' 
  },
]

export default {
    readingData,
    status,
    questionTypes,
    mainNavigation,
    topNavigation
}